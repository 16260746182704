'use strict';

/* global braintreeUtils braintree $ */

var btClientInstancePromise;

/**
 * Returns a promise that resolves with the HostedFields instance.
 * @param {Object} hostedFieldsParams Object of hosted fieds parameters
 * @returns {Object} Object with hosted field parameters
 */
function createHostedFields(hostedFieldsParams) {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            return braintree.hostedFields.create({
                client: btClientInstance,
                styles: hostedFieldsParams.styles,
                fields: hostedFieldsParams.configs,
                options: {
                    enableCORS: true
                }
            });
        });
}

/**
 * Launch the 3D Secure login flow, returning a nonce payload.
 * @param {string} nonce Payment method nonce
 * @param {string} bin Payment method bin
 * @param {number} amount Total amount
 * @param {string} email Email
 * @param {Object} billingData Object with Credit Card billing data
 * @param {Object} shippingAdditionalInfo Object with Credit Card shipping info
 * @returns {Object} 3DS instance
 */
function apply3dSecureValidation(nonce, bin, amount, email, billingData, shippingAdditionalInfo) {
    return btClientInstancePromise
        .then(function (btClientInstance) {
            return braintree.threeDSecure.create({
                client: btClientInstance,
                version: 2
            })
            .then(function (threeDSecure) {
                return threeDSecure.verifyCard({
                    amount: amount,
                    nonce: nonce,
                    bin: bin || '',
                    email: email,
                    challengeRequested: true,
                    billingAddress: {
                        givenName: billingData.firstName,
                        surname: billingData.lastName,
                        phoneNumber: billingData.phone,
                        streetAddress: billingData.address1,
                        extendedAddress: billingData.address2,
                        locality: billingData.city,
                        region: billingData.stateCode,
                        postalCode: billingData.postalCode,
                        countryCodeAlpha2: billingData.country
                    },
                    additionalInformation: shippingAdditionalInfo,
                    onLookupComplete: function (data, next) {
                        next();
                    }
                });
            });
        });
}

/**
 * Tokenizes a Credit Card and returns a nonce payload.
 * @param {HostedFileds} hfInstance Hosted fieled instance
 * @param {Object} tokenizationOptions Credit Card tokenization option
 * @returns {Promise} A promise that resolves with a tokenizePayload.
 */
function tokenize(hfInstance, tokenizationOptions) {
    var tokenizeOption = tokenizationOptions || {};

    return hfInstance.tokenize(tokenizeOption);
}

/**
 * Clear the value of the field
 * @param {HostedFileds} hfInstance Hosted fieled instance
 */
function clearHostedFields(hfInstance) {
    hfInstance.clear('cardholderName');
    hfInstance.clear('number');
    hfInstance.clear('cvv');
    hfInstance.clear('expirationDate');
}

/**
 *
 * @param {Promise} braintreeClientInstancePromise Braintree Credit Card instance promise
 */
function init(braintreeClientInstancePromise) {
    btClientInstancePromise = braintreeClientInstancePromise;
}

module.exports = {
    init,
    createHostedFields,
    apply3dSecureValidation,
    tokenize,
    clearHostedFields
};
