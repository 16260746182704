'use strict';

var loaderInstance = require('../../loaderHelper');
var creditCardVaultManager = require('./creditCardVaultManager');

var braintreeCreditCardModel;
var addNewCreditCardLoader;

// Helpers
var creditCardAccountHelper = require('../helpers/creditCardAccountHelper');

// Loader selectors
var $braintreeCreditCardLoader = document.querySelector('#braintreeCreditCardLoader');
var $customCreditCardErrorContainer = document.querySelector('#customCreditCardErrorContainer');

var errHandlingModel;
var ccMessages;

/**
 * Check whenever is new card is presented on Storefront and in VaultMgr. If yes - render error message.
 * @param {Promise} clientInstancePromise client instance promise
 * @param {Object} btPayload payload from Braintree
 * @param {Object} errorList object with errors
 * @returns {Promise} promise with reject status in case if new card already exists in VaultMgr and on Storefront (buyer's wallet)
 */
function rejectIfCardExistsInVault(clientInstancePromise, btPayload, errorList) {
    var payloadDetails = btPayload.details;
    var lastFour = payloadDetails.lastFour.toString();
    var type = payloadDetails.cardType.toLowerCase();

    return creditCardVaultManager.isNewCardCanBeStored(clientInstancePromise, lastFour, type)
        .then(function (newCardCanBeStored) {
            if (!newCardCanBeStored) {
                // eslint-disable-next-line no-undef
                return Promise.reject(errorList.CARD_ALREADY_EXIST_ERROR_MESSAGE);
            }
        });
}

/**
 * Stores new Credit Card on account page
 * @param {Object} event Event object
 * @returns {void}
 */
function storeNewCreditCard(event) {
    var isCheckoutPage = false;
    var customErrorList = JSON.parse($customCreditCardErrorContainer.getAttribute('data-errors'));

    // Clears error messages on the top of the Account Page
    errHandlingModel.hideError();

    try {
        if (!braintreeCreditCardModel) {
            throw ccMessages.CLIENT_MISSING_GATEWAY_CONFIGURATION;
        }

        addNewCreditCardLoader.show();

        var clientInstancePromise = braintreeCreditCardModel.getClientInstancePromise();

        braintreeCreditCardModel.tokenize(isCheckoutPage)
            .then(function (btModelPayload) {
                var tokenizePayload = btModelPayload.btTokenizePayload;
                var $addCreditCardForm = $('.js_braintree_addCreditCardForm');
                var addCreditCardFormUrl = $addCreditCardForm.attr('action');
                var formData;

                return rejectIfCardExistsInVault(clientInstancePromise, tokenizePayload, customErrorList)
                    .then(function () {
                        // Sets value of Credit Card hidden fields needed for server side
                        creditCardAccountHelper.setCardHiddenFields(tokenizePayload);

                        // Call to Braintree-AccountAddCreditCardHandle preparetion
                        formData = $addCreditCardForm.serialize();

                        return $.ajax({
                            url: addCreditCardFormUrl,
                            type: 'post',
                            dataType: 'json',
                            data: formData
                        })
                            .then(function (response) {
                                if (!response.success) {
                                    errHandlingModel.creditCardErrorHandler(response.error);
                                } else {
                                    location.href = response.redirectUrl;
                                }
                            });
                    });
            })
            .catch(function (error) {
                errHandlingModel.creditCardErrorHandler(error);
            })
            .finally(function () {
                addNewCreditCardLoader.hide();
            });

        event.preventDefault();
        event.stopPropagation();
    } catch (err) {
        errHandlingModel.creditCardErrorHandler(err);
    }
}

/**
 * Inits Credit Card account loaders
 */
function initCreditCartAccountLoaders() {
    var braintreeCreditCardLoaderExists = Boolean($braintreeCreditCardLoader);

    if (braintreeCreditCardLoaderExists) {
        addNewCreditCardLoader = loaderInstance($braintreeCreditCardLoader);
    }
}

/**
 * Init Credit Card Account functionality
 * @param {Object} btCreditCardModel Braintree Credit Card model
 * @param {Constructor} errorHandlingModel Error handling model
 * @param {Object} creditCardMessages List of Credit Card error messages
 */
function init(btCreditCardModel, errorHandlingModel, creditCardMessages) {
    var addNewCreditCardFormExists = Boolean(document.querySelector('.js_braintree_addCreditCardForm'));
    errHandlingModel = errorHandlingModel;
    ccMessages = creditCardMessages;

    var clientInstancePromise = btCreditCardModel.getClientInstancePromise();

    initCreditCartAccountLoaders();
    creditCardVaultManager.removeFromDomCardsWhichAreNotInVaultMgr(clientInstancePromise);

    braintreeCreditCardModel = btCreditCardModel;

    // "Store new Card" button functionality initiation (works only when 3DS is disabled)
    if (addNewCreditCardFormExists) {
        $('.braintreeAddNewCardBlock').on('click', '.braintreeCreditCardBtn', storeNewCreditCard);
    }
}

module.exports = {
    init
};
