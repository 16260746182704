'use strict';

var loaderInstance = require('../../loaderHelper');
var srcHelper = require('../helpers/srcHelper');
var creditCardVaultManager = require('../../creditcard/components/creditCardVaultManager');

// Models
var ErrorHandlingBaseModel = require('../errorhandler/errorHandlingBaseModel');
var SrcAccountModel = require('../models/srcAccountModel');

var $braintreeSrcLoader = document.querySelector('.braintreeSrcLoader');
var $braintreeSrcButton = document.querySelector('.js_braintree_src_account_button');

/**
 * Inits SRC account functionality on the Account Page
 * @param {Promise} clientInstancePromise client instance promise
 */
function init(clientInstancePromise) {
    var srcButtonConfigurations = srcHelper.getBraintreeSRCButtonConfigs();
    var $braintreeSrcDeviceDataInput = null;
    var srcLoader = loaderInstance($braintreeSrcLoader);
    var errorHandlingBaseModel = new ErrorHandlingBaseModel(srcButtonConfigurations.messages);

    var SrcAccountModelInstance = new SrcAccountModel(
        clientInstancePromise,
        srcButtonConfigurations,
        $braintreeSrcDeviceDataInput,
        srcLoader,
        errorHandlingBaseModel
    );

    // Hides errors on the Account Page
    $braintreeSrcButton.addEventListener('click', errorHandlingBaseModel.hideError);

    creditCardVaultManager.removeFromDomCardsWhichAreNotInVaultMgr(clientInstancePromise);
    SrcAccountModelInstance.initSrcButton();
}

module.exports = {
    init
};
