'use strict';

var payPalSessionAccountComponent = require('./payPalSessionAccount');
var payPalStoredBillingAddressComponent = require('./payPalStoredBillingAddress');
var {
    isVaultModeEnabled,
    isOrderIntentEnabled,
    getAccountType,
    setNonceInputValue,
    hideSavePayPalAccountBlock,
    displaySavePayPalAccountBlock,
    displayPayPalButton,
    hidePayPalButon,
    displayContinueButton,
    hideContinueButon,
    isBuyerAuthenticated,
    selectPayPalCheckoutTab,
    setBraintreePayPalEmailInput,
    handleStoredAccountsShowing
} = require('../helpers/payPalListHelper');
var billingFormHelper = require('../helpers/payPalBillingFormHelperGlobal');

var $payPalAccountList;
var $continueButton;
var $savePaypalAccountCountainer;
var $braintreePayPalNonce;
var $paypalButton;
var $payPalTab;
var $braintreePaypalEmail;

var constantList = {
    SESSION_ACCOUNT: 'session_account',
    NEW_ACCOUNT: 'new_account',
    STORED_ACCOUNT: 'stored_account'
};

/**
 * The function which manages what should be done when session/stored/new account was selected from the account dropdown
 */
function accountsListChange() { // eslint-disable-line require-jsdoc
    var accountType = getAccountType($payPalAccountList, constantList);
    var $selectedPayPalAccountOption = $payPalAccountList.options[$payPalAccountList.selectedIndex];

    $continueButton.removeEventListener('click', payPalSessionAccountComponent.removeSessionNonce);

    // eslint-disable-next-line default-case
    switch (accountType) {
        // Buyer selected new PayPal account
        case constantList.NEW_ACCOUNT:
            displayPayPalButton($paypalButton);
            hideContinueButon($continueButton);
            break;

        // Buyer selected session account
        case constantList.SESSION_ACCOUNT:
            var $selectedOption = $payPalAccountList.selectedOptions[0];
            var selectedOptionNonceValue = $selectedOption.getAttribute('data-nonce');

            setNonceInputValue($braintreePayPalNonce, selectedOptionNonceValue);
            displayContinueButton($continueButton);
            hidePayPalButon($paypalButton);

            selectPayPalCheckoutTab($payPalTab);
            break;

        // Buyer selected stored account
        case constantList.STORED_ACCOUNT:
            var selectedAccountEmail = $selectedPayPalAccountOption.text;

            setNonceInputValue($braintreePayPalNonce, '');
            displayContinueButton($continueButton);
            hidePayPalButon($paypalButton);

            selectPayPalCheckoutTab($payPalTab);
            setBraintreePayPalEmailInput($braintreePaypalEmail, selectedAccountEmail);

            // Display billing address of selected PayPal account
            payPalStoredBillingAddressComponent.displaySelectedStoredBillingAddress($selectedPayPalAccountOption);

            $continueButton.addEventListener('click', payPalSessionAccountComponent.removeSessionNonce);
            break;
    }
}

/**
 * Init PayPal Account list functionality
 */
function init() {
    $payPalAccountList = document.querySelector('#braintreePaypalAccountsList');
    $savePaypalAccountCountainer = document.querySelector('#braintreeSavePaypalAccountContainer');
    $braintreePayPalNonce = document.querySelector('#braintreePayPalNonce');
    $paypalButton = document.querySelector('.js_braintree_paypal_billing_button');
    $continueButton = document.querySelector('button.submit-payment');
    $payPalTab = document.querySelector('.paypal-tab');
    $braintreePaypalEmail = document.querySelector('#braintreePaypalEmail');
    var buyerAuthenticated = isBuyerAuthenticated($payPalAccountList);

    // Add on change "account list" event
    $payPalAccountList.addEventListener('change', accountsListChange);
    // Execute "account list" change function to setup init values
    accountsListChange();
    // Check whether buyer has proceeded with the same email which is already stored
    // if yes we hide stored account with the same email in dropdown
    // if no we show all existent PP accounts in dropdown (stored + new session account)
    handleStoredAccountsShowing(buyerAuthenticated);
}

module.exports = {
    init
};
