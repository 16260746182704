'use strict';

module.exports = function () {
    // Used only for Paypal. In future "client token" generation approach will be refactored
    var paymentMethodGeneral = require('./paymentMethodGeneral');
    var helper = require('./helper');

    // General global variables
    var clientTokenPromise = paymentMethodGeneral.createGeneralClientInstancePromise();
    var isCheckoutPage = Boolean(document.querySelector('#checkout-main'));

    /* ------------------------General Account functionality------------------------ */
    var btAccountBusinessLogic = require('./braintreeAccount');
    // Common Credit Card and SRC Account functionality
    btAccountBusinessLogic.initAccount();

    /* ------------------------General Checkout functionality----------------------- */
    if (isCheckoutPage) {
        paymentMethodGeneral.fillPaymentSammaryContainer();
        paymentMethodGeneral.initPaymentMethodTabClickBehavior();
    }

    /* ------------------------Credit Card------------------------ */
    var creditCardBusinessLogic = require('./creditcard/creditCard');

    var creditCardOnAccountEnabled = Boolean(document.querySelector('.js_braintree_addCreditCardForm'));
    var creditCardEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_creditCardContent'));

    creditCardBusinessLogic.init();

    if (creditCardOnAccountEnabled) {
        creditCardBusinessLogic.initAccount();
    }

    if (creditCardEnabledOnCheckout) {
        creditCardBusinessLogic.initCheckout();
    }

    /* --------------------------PayPal-------------------------- */
    var payPalBusinessLogic = require('./paypal/payPal');
    var payPalStaticButtonComponent = require('./paypal/components/payPalStaticImage');

    var payPalEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_paypalContent'));
    var payPalEnabledOnProduct = Boolean(document.querySelector('.prices-add-to-cart-actions .js_braintree_paypal_cart_button'));
    var payPalEnabledOnCart = Boolean(document.querySelector('.cart-page .js_braintree_paypal_cart_button'));
    var payPalEnabledOnAccountPage = Boolean(document.querySelector('.paypal-accounts'));

    // PayPal Static Button. Static button on MiniCart will be inited inside MiniCart component
    var payPalStaticButtonEnabledOnProduct = Boolean(document.querySelector('.braintree_pdp_button .braintree-static-paypal-button'));
    var payPalStaticButtonEnabledOnCart = Boolean(document.querySelector('.cart-page .braintree-static-paypal-button'));

    payPalBusinessLogic.init(clientTokenPromise);

    if (payPalStaticButtonEnabledOnProduct) {
        payPalStaticButtonComponent.initOnPdp();
    }

    if (payPalStaticButtonEnabledOnCart) {
        payPalStaticButtonComponent.initOnCart();
    }

    if (payPalEnabledOnCheckout) {
        payPalBusinessLogic.initCheckout();
    }

    if (payPalEnabledOnProduct) {
        payPalBusinessLogic.initProduct();
    }

    // Validation on enabled/disabled PayPal button for MiniCart located isinside MiniCart component
    payPalBusinessLogic.initMiniCart();

    if (payPalEnabledOnCart) {
        payPalBusinessLogic.initCart();
    }

    if (payPalEnabledOnAccountPage) {
        payPalBusinessLogic.initAccount();
    }

    /* --------------------------GooglePay------------------------- */
    var googlePayBusinnessLogic = require('./googlepay/googlePay');

    var googlePayEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_googlepayContent'));
    var googlePayEnabledOnCart = Boolean(document.querySelector('.braintree-cart-google-button'));

    googlePayBusinnessLogic.init();

    if (googlePayEnabledOnCheckout) {
        googlePayBusinnessLogic.initCheckout();
    }

    if (googlePayEnabledOnCart) {
        googlePayBusinnessLogic.initCart();
    }

    /* --------------------------ApplePay------------------------- */
    var applePayBusinessLogic = require('./applepay/applePay');

    var applePayEnabledOnCheckout = Boolean(document.querySelector('.js_braintree_applepayContent'));
    var applePayEnabledOnCart = Boolean(document.querySelector('.braintree-cart-apple-button'));

    applePayBusinessLogic.init();

    if (applePayEnabledOnCart) {
        applePayBusinessLogic.initCart();
    }

    if (applePayEnabledOnCheckout) {
        applePayBusinessLogic.initCheckout();
    }

    /* --------------------------Venmo---------------------------- */
    var venmoBusinessLogic = require('./venmo/venmo');

    var isVenmoEnabledOnAccountPage = Boolean(document.querySelector('.venmo-accounts'));
    var isVenmoEnabledOnBillingPage = Boolean(document.querySelector('.js_braintree_venmoContent'));

    venmoBusinessLogic.init();

    if (isVenmoEnabledOnAccountPage) {
        venmoBusinessLogic.initAccount();
    }

    if (isVenmoEnabledOnBillingPage) {
        venmoBusinessLogic.initBillingCheckout();
    }

    /* --------------------------SRC------------------------------ */
    var srcBusinessLogic = require('./src/src');

    var isSrcEnabledOnAccountPage = Boolean(document.querySelector('.js_braintree_addSrcAccountForm'));
    var isSrcEnabledOnCartPage = Boolean(document.querySelector('.braintree-cart-src-button'));
    var isSrcEnabledOnBillingPage = Boolean(document.querySelector('.js_braintree_srcContent'));

    srcBusinessLogic.init();

    if (isSrcEnabledOnAccountPage) {
        srcBusinessLogic.initAccount();
    }

    if (isSrcEnabledOnCartPage) {
        srcBusinessLogic.initCartCheckout();
    }

    if (isSrcEnabledOnBillingPage) {
        srcBusinessLogic.initBillingCheckout();
    }

    /* --------------------------LPM-------------------------- */
    var lpmBusinessLogic = require('./local/lpm');
    var isLpmEnebledOnBillingPage = Boolean(document.querySelector('.js_braintree-lpmContent'));

    if (isLpmEnebledOnBillingPage) {
        lpmBusinessLogic.init();
    }

    $('.payment-options[role=tablist] a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        $( '.payment-options').find('.tab-pane').removeClass('active');
        var tabContent = $(e.currentTarget).next();
        tabContent.find('.tab-pane').addClass('active');
        helper.paymentMethodChangeHandle(e.target);
        const cardList = document.getElementById('braintreeCreditCardList');
        const paypalList = document.getElementById('braintreePaypalAccountsList');
        var changeEvent;

        if (e.target.hash === '#creditcard-content' && cardList) {
            if (typeof (Event) === 'function') {
                changeEvent = new Event('changeEvent');
                cardList.addEventListener('changeEvent', function () {
                    'change';
                }, false);
            } else {
                changeEvent = document.createEvent('Event');
                changeEvent.initEvent('changeEvent', true, true);
            }
            cardList.dispatchEvent(changeEvent);
        } else if (e.target.hash === '#paypal-content' && paypalList) {
            if (typeof (Event) === 'function') {
                changeEvent = new Event('changeEvent');
                paypalList.addEventListener('changeEvent', function () {
                    'change';
                }, false);
            } else {
                changeEvent = document.createEvent('Event');
                changeEvent.initEvent('changeEvent', true, true);
            }
            paypalList.dispatchEvent(changeEvent);
        }
    });
};
